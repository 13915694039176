import styled from "styled-components";

export const Logo = styled("div")`
  margin: 20px 0;

  & img {
    height: 315px;
    width: 315px;
  }
`;
